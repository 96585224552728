// noinspection ES6PreferShortImport

import { ErrorHandler, inject, Injectable, Injector, Provider } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { SentryErrorHandler } from '@sentry/angular-ivy';
import { FLOORI_ENV, errorsToSkip, productNotFoundErrors } from '@floori-web/constants';
import { FlooriEnv } from '@floori-web/models';
import { Router } from '@angular/router';
import { DeviceService } from '@floori-web/tools';
import { AppFacade } from '../../../store/src/lib/facades';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private readonly device = inject(DeviceService);
    private readonly chunkLoadErrorMessage = 'chunkloaderror';
    private readonly chunkLoadErrorRegexp = /.*loading chunk \d+ failed.*/;
    private readonly env = inject<FlooriEnv>(FLOORI_ENV);
    private readonly sentryHandler?: SentryErrorHandler;

    constructor(private injector: Injector) {
        if (this.env.sentryKey) {
            Sentry.init({
                dsn: this.env.sentryKey,
                environment: this.env.envName,
                release: this.env.version,
            });
            Sentry.configureScope(scope => {
                scope.setExtra('orientation', this.device.orientation);
            });
            this.sentryHandler = Sentry.createErrorHandler({});
        }
    }

    handleError(error: ErrorEvent): void {
        const errorMessage = error.message?.toLowerCase() || '';
        const shouldSkip = errorsToSkip.some(errorToSkip =>
            errorMessage.includes(errorToSkip.toLowerCase()),
        );
        const isNotFoundError = productNotFoundErrors.some(notFoundError =>
            errorMessage.includes(notFoundError),
        );

        if (
            errorMessage.includes(this.chunkLoadErrorMessage) ||
            this.chunkLoadErrorRegexp.test(errorMessage)
        ) {
            this.injector.get(AppFacade)?.setError(true, 'chunkLoadErrorMessage');
            return;
        } else if (shouldSkip || isNotFoundError) {
            return;
        }

        if (this.env.sentryKey) {
            this.sentryHandler?.handleError(error);
            return;
        }

        console.error(error);
    }
}

export const flooriErrorHandler: Provider = {
    provide: ErrorHandler,
    useClass: GlobalErrorHandler,
};

export const flooriTraceProvider: Provider = {
    provide: Sentry.TraceService,
    deps: [Router, FLOORI_ENV],
    useFactory: (router: Router, env: FlooriEnv) =>
        !!env.sentryKey ? new Sentry.TraceService(router) : null,
};
