import { inject, Injectable } from '@angular/core';
import { distinctUntilChanged, map, Observable } from 'rxjs';
import { BaseFacade } from '@floori-web/utils/abstract';
import { FLOORI_STORAGE_PROVIDER, LocalStorageKeys, StorageProvider } from '@floori-web/models';
import { selectSplash } from '../selectors';
import { changeSplashVisibility, errorActions, visitorActions } from '../actions';

@Injectable({ providedIn: 'root' })
export class AppFacade extends BaseFacade {
    private readonly storage = inject<StorageProvider>(FLOORI_STORAGE_PROVIDER);
    readonly splash$ = this.store.select(selectSplash).pipe(distinctUntilChanged());

    get visitorId(): Observable<string> {
        return this.storage
            .get<string>(LocalStorageKeys.visitorId)
            .pipe(map(result => result || ''));
    }

    constructor() {
        super();
    }

    setSplashScreen(visible: boolean, logoUrl?: string): void {
        return this.store.dispatch(
            changeSplashVisibility({
                visible,
                logoUrl,
            }),
        );
    }

    updateVisitorId(visitorId: string): void {
        this.store.dispatch(visitorActions.setVisitorId({ visitorId }));
    }

    setError(show: boolean, message?: string): void {
        this.store.dispatch(errorActions.setError({ show, message }));
    }

    checkStorageAvailability(): void {
        try {
            const local = window.localStorage;
            const session = window.sessionStorage;
        } catch (e) {
            this.setError(true, 'storageAccessDenied');
        }
    }
}
