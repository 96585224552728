export const environment = {
    production: false,
    envName: 'development',
    apiUrl: 'https://floori-api-eks.actumwork.pl/new-floori',
    cdnUrl: 'https://d3qhrpk421cfxh.cloudfront.net',
    flooriWebsite: 'https://floori.io',
    bookDemoUrl: 'https://floori.io/LANG/contact/',
    supportEmail: 'support@floori.io',
    version: '2024-10-11-638',
    sentryKey: 'https://db9fad6536d3471aba6475e89d4b7d55@sentry.actumwork.pl/7',
    gtmUrl: 'https://floori-gtm-analytics-dev-eks.actumwork.pl',
    gtmKey: 'GTM-WDNP3FQ',
    interactionKey: '',
    showDebugData: false,
    showDevTools: true,
    bonaAdditionsIds: '5443e2ccafafeda4918835cc1563cdbd1f9fc0320df53d57c6e45dbd370f5a6cd34a5867431ce43026a7b2120717a3b7a212',
};
